import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const FocusPage = () => (
  <Layout>
    <SEO title="Focus Areas" description="" lang="en" meta={[]} />

    <div className="title-banner focus">
      <h1>Focus Areas</h1>
    </div>

    {/* Data */}
    <section className="focus-areas-section bg-darkened">
      <div className="d-flex justify-content-center">
        <h2 className="mb-5 underline lg text-center">
          The Four Research Thrusts at Flapmax
        </h2>
      </div>
      <div className="container ">
        {/* First Row */}
        <div className="row justify-content-between">
          <div className="col-lg dark">
            <h3 className="my-3 text-center">Data</h3>
            <p>
              As with all 4 of our focus areas, data is fundamental to Applied
              AI, enabling key insights through the application of advanced
              machine learning algorithms in areas such as data mining, data
              integration, graph analytics, data science, and data management.
            </p>
          </div>
          <div className="col-lg">
            <h3 className="my-3 text-center">SciML Apps</h3>
            <p>
              With the rapid increase in artificial intelligence technologies in
              many science and engineering domains such as computer vision,
              language understanding, and recommender systems, HPC applications
              are beginning to adopt AI for a variety of scientific problems
              including weather &amp; climate studies, high-energy physics,
              bioinformatics, and computational chemistry.
            </p>
          </div>
        </div>
        {/* Second Row */}
        <div className="row justify-content-between">
          <div className="col-lg">
            <h3 className="my-3 text-center">System Software</h3>
            <p>
              From sensors and IoT devices to edge processing and cloud
              management and storage, disparate systems (including both hardware
              and software) must interoperate seamlessly to solve highly complex
              tasks within tolerable limits like latency, bandwidth and power
              while delivering rich experiences for users.
            </p>
          </div>
          <div className="col-lg dark">
            <h3 className="my-3 text-center">Hardware</h3>
            <p>
              Heterogeneous computing hardware will play an important role in
              next-gen mission critical systems. Combining Data, SciML and
              Software together, hardware needs to evolve to support fast
              changing demands in industry, including areas such as hardware
              acceleration, 5G platforms, reconfigurable computing and SoC.
            </p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default FocusPage
